import * as React from "react"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}
const paragraphStyles = {
  marginBottom: 48,
}


// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>Amicracy</title>
      <h1 style={headingStyles}>
        Amicracy
      </h1>
      <p style={paragraphStyles}>
        Please contact us by e-mail in case you need help or have some questions: hello (ät) amicracy.com
      </p>
    </main>
  )
}

export default IndexPage
